<template>
  <Main>
    <div class="auth-contents registration-form">
      <a-row :gutter="15">
        <a-col class="mt-10" :xs="24">
          <sdCards headless>
            <basic-steps step="withIcon" size="default" :data="stepsWithStatus" :current="current" />
          </sdCards>
        </a-col>
        <a-col class="mt-10" :xs="24" v-if="reapplyMode && stepsWithStatus[current]?.description === '(rejected)'">
          <sdCards headless>
            <h3>{{$t('models.companyProfileStep.attributes.rejectionReason')}}:</h3>
            {{$_.find(companyProfileSteps?.steps, {name: stepsWithStatus[current].key})?.rejectionReason || '--'}}
          </sdCards>
        </a-col>
        <a-col :xs="24">
          <company-details
            v-show="current === 0"
            :data="formState.companyDetails"
            :loading="buttonLoading"
            @submit:companyDetails="handleCompanyDetails"
            :companyEditMode="true"
          />
          <product-items
            v-show="current === 1"
            :data="formState.productItems"
            @previousStep="prevStep"
            :loading="buttonLoading"
            @submitForm="handleProductItems"
            @deleteProducts="setRemovedProducts"
            :lastStep="!reapplyMode"
            :key="`products-${productsKey}`"
          />
          <terms-and-conditions
            v-show="reapplyMode && current === 2"
            :loading="buttonLoading"
            :value="formState.termsAndConditions"
            @previousStep="prevStep"
            @finishRegistration="handleAgreements"
          />
        </a-col>
      </a-row>
    </div>
  </Main>
</template>

<script>
import {
  reactive, ref, computed, watch
} from 'vue';
import {useStore} from 'vuex';
import _ from 'lodash';
import BasicSteps from '@/components/steps/Basic';
import CompanyDetails from '@/components/registration/CompanyDetails';
import ProductItems from '@/components/registration/ProductItemsForm';
import {Main} from '@/components/shared/styledComponents/Main';
import {useI18n} from 'vue-i18n';
import TermsAndConditions from '@/components/registration/TermsAndConditions';
import {useRouter} from 'vue-router';

export default {
  components: {
    Main,
    BasicSteps,
    CompanyDetails,
    ProductItems,
    TermsAndConditions
  },
  setup() {
    const {
      dispatch, commit, getters, state
    } = useStore();
    commit('setUserType', 'ClientUser');
    const currentUser = computed(() => state.session.currentUser);
    const router = useRouter();

    dispatch('toggleLoading', true);
    Promise.all([
      dispatch('getCountries'),
      dispatch('getList', 'PRODUCT_CATEGORIES'),
      dispatch('getCompanyProfileSteps', currentUser.value._jv?.relationships.company._jv.id),
      dispatch('getProductItems')
    ]).then(() => {
      dispatch('toggleLoading', false);
      initClient();
    } );

    const companyProfileSteps = computed(() => getters.companyProfileSteps);
    const reapplyMode = state.session.currentUser?._jv.relationships.company?.status === 'rejected';
    const productItems = computed(() => state.shipmentRequests.productItems);

    const loading = ref(false);
    const buttonLoading = ref(false);
    const status = ref('process');
    const current = ref(0);
    const productsKey = ref(0);

    const {t} = useI18n();
    const steps = [
      {
        id: 1,
        title: t('views.registration.companyDetails'),
        icon: 'home',
        key: _.snakeCase('companyDetails')
      },
      {
        id: 2,
        title: t('views.registration.products'),
        icon: 'shopping-cart',
        key: _.snakeCase('productItems')
      }
    ];

    const stepsWithStatus = computed(() => {
      if (_.isEmpty(companyProfileSteps.value?.steps)) return [];
      const basicSteps = steps;
      if (reapplyMode && !_.find(basicSteps, {id: 3})) {
        basicSteps.push({
          id: 3,
          title: t('views.registration.termsAndConditions'),
          icon: 'clipboard',
          key: 'agreement'
        });
      }
      _.forEach(basicSteps, (step) => {
        const stepStatus = _.find(companyProfileSteps.value.steps, {name: step.key})?.status;
        if (!stepStatus) return;
        step['description'] = stepStatus === 'unset' ? '' : `(${stepStatus})`;
      });

      return basicSteps;
    });

    watch(stepsWithStatus, (value) => {
      if (!_.isEmpty(value) && reapplyMode) {
        const firstRejectedStep = _.findIndex(stepsWithStatus.value, (step) => {
          return step.description === '(rejected)';
        });
        current.value = firstRejectedStep > -1 ? firstRejectedStep : 0;
      }
    }, {immediate: true, deep: true});


    const formState = reactive({
      companyDetails: {}, productItems: [], removedProducts: [], termsAndConditions: false
    });
    const saveState = (key, data) => Object.assign(formState[key], data);
    const initClient = () => {
      if (currentUser.value) {
        const companyDetails = {};
        companyDetails.id = currentUser.value._jv.relationships.company._jv.id;
        _.forEach(['email', 'firstName', 'lastName'], (key) => companyDetails[key] = currentUser.value[key]);
        _.forEach(['serviceTypes', 'city', 'country', 'street', 'name',
          'phoneCode', 'registrationNumber', 'contactPersonName', 'contactPersonPosition'], (key) => {
          companyDetails[key] = currentUser.value._jv.relationships.company[key] || undefined;
        });
        companyDetails['phone'] = parseInt(currentUser.value._jv.relationships.company['phone']) || undefined;
        _.forEach(['tradeLicense', 'businessRegistrationCertificate'], (key) => {
          companyDetails[key] = [{
            uid: currentUser.value._jv.relationships.company[key]?.id,
            name: currentUser.value._jv.relationships.company[key]?.filename,
            status: 'done',
            url: `${process.env.VUE_APP_GCE_BASE_URL}${currentUser.value._jv.relationships.company[key]?.url}`
          }];
        });
        saveState('companyDetails', companyDetails);
        const products = _.map(productItems.value, (item) => {
          const formattedItem = {};
          _.forEach(['hsCode', 'descriptionOfGoods', 'countryOfOrigin', 'countryOfOriginName', 'comment', 'productNumber', 'itemType', 'linkedToShipment'], (key) => formattedItem[key] = item[key]);
          formattedItem.id = item._jv.id;
          formattedItem.productCategoryId = item._jv.relationships.productCategory.id;
          if (item.dataSheet) {
            formattedItem.dataSheet = [{
              uid: item.dataSheet.id,
              name: item.dataSheet.filename,
              status: 'done',
              url: `${process.env.VUE_APP_GCE_BASE_URL}${item.dataSheet.url}`
            }];
          }
          return formattedItem;
        });
        saveState('productItems', products);
        productsKey.value += 1;
        formState.termsAndConditions = currentUser.value._jv.relationships.company.termsAndConditions;
      }
    };


    const handleCompanyDetails = async (data) => {
      saveState('companyDetails', data);
      saveStep(true, true);
    };

    const handleProductItems = async (data) => {
      formState.productItems = data;
      saveStep(reapplyMode, reapplyMode);
    };

    const handleAgreements = async () => {
      formState.termsAndConditions = true;
      saveStep(false, false);
    };

    const setRemovedProducts = (data) => {
      saveState('removedProducts', data);
    };

    const saveStep = async (skipToaster, saveDraft) => {
      buttonLoading.value = true;
      const formData = new FormData();
      formatCompanyDetails(formData);
      dispatch('updateCompanyRegistration', {
        data: formData, saveDraft, skipToaster, edit: true
      }).then((status) => {
        buttonLoading.value = false;
        if (status === 200) {
          current.value === 2 ? router.push('/') : current.value += 1;
        }
      });
    };

    const prevStep = () => {
      current.value -= 1;
    };

    const formatCompanyDetails = (formData) => {
      if (reapplyMode && current.value !== 2) formData.append(_.snakeCase('skipUpdateStatus'), true);
      if (current.value === 0 ) {
        _.forEach(formState.companyDetails, (value, key) => {
          if ((key === 'tradeLicense' || key === 'businessRegistrationCertificate')) {
            if (value[0] instanceof File) {
              formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase(key)}]`, value[0]);
            }
          } else {
            formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase(key)}]`, value);
          }
        });
        if (reapplyMode) formData.append(`${_.snakeCase('clientAttributes')}[status]`, 'rejected');
      } else if (current.value === 1) {
        formData.append(`${_.snakeCase('clientAttributes')}[id]`, formState.companyDetails.id);
        let currentIndex = 0;
        _.forEach(formState.productItems, (item, index) => {
          _.forEach(item, (value, key) => {
            if (key === 'dataSheet' && value && value[0] instanceof File) {
              formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase('productItemsAttributes')}][${index}][${_.snakeCase(key)}]`, value[0]);
            } else if (key !== 'dataSheet') {
              formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase('productItemsAttributes')}][${index}][${_.snakeCase(key)}]`, value || '');
            }
          });
          currentIndex += 1;
        });

        _.forEach(formState.removedProducts, (item) => {
          formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase('productItemsAttributes')}][${currentIndex}][id]`, item.id);
          formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase('productItemsAttributes')}][${currentIndex}][_destroy]`, true);
          currentIndex += 1;
        });
      } else {
        formData.append(`${_.snakeCase('clientAttributes')}[id]`, formState.companyDetails.id);
        formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase('termsAndConditions')}]`, formState.termsAndConditions);
      }
    };

    return {
      loading,
      buttonLoading,
      status,
      reapplyMode,
      current,
      stepsWithStatus,
      formState,
      currentUser,
      companyProfileSteps,
      saveState,
      prevStep,
      handleCompanyDetails,
      handleProductItems,
      setRemovedProducts,
      productsKey,
      handleAgreements
    };
  }
};
</script>
